import * as MantineCore from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import Link from 'next/link';
// // @ts-ignore
// import iso3311a2 from 'iso-country-lookup'

import * as Types from '../../declarations';
import * as DisplayUtil from '../../lib/display';
// import * as HelperUtil from '../../lib/helper'
import HTMLContent from '../HTMLContent';
import CountryBadge from '../CountryBadge';
const TripsCarousel = ({
  trips,
  version,
  altBase
}: {
  trips: Types.App.Types.CarouselTrip[];
  version: Types.VERSION;
  altBase: string;
}) => {
  return <div className="trip-carousel" data-sentry-component="TripsCarousel" data-sentry-source-file="TripsCarousel.tsx">
			<Carousel withIndicators slideSize="30%" slideGap="md" align="start" breakpoints={[{
      maxWidth: 'xl',
      slideSize: '30%'
    }, {
      maxWidth: 'lg',
      slideSize: '30%'
    }, {
      maxWidth: 'md',
      slideSize: '45%'
    }, {
      maxWidth: 'sm',
      slideSize: '50%'
    }, {
      maxWidth: 'xs',
      slideSize: '80%'
    }]} data-sentry-element="Carousel" data-sentry-source-file="TripsCarousel.tsx">
				{trips.map((trip, idxTrip) => <Carousel.Slide key={idxTrip}>
						<MantineCore.Card radius="md" withBorder padding="xl">
							<MantineCore.Card.Section>
								<Carousel withIndicators loop classNames={{
              root: 'carousel',
              controls: 'carouselControls',
              indicator: 'carouselIndicator'
            }}>
									{/* {slides} */}
									{trip.images.map((image, idxImages) => <Carousel.Slide key={idxImages}>
											<MantineCore.Image src={DisplayUtil.getUploadThumbPath(image.id, 'medium')} height={180} alt={`${altBase} - ${trip.name}`} />
										</Carousel.Slide>)}
								</Carousel>
							</MantineCore.Card.Section>

							<MantineCore.Group position="apart" mt="lg">
								<MantineCore.Text fw={500} fz="lg">
									{trip.name}
								</MantineCore.Text>

								<MantineCore.Text fz="lg" c="dimmed">
									{trip.detail}
								</MantineCore.Text>
							</MantineCore.Group>
							<MantineCore.Group spacing={5} mt="xs">
								{trip.countries.map((isoCode: string, countryIndex: number) => <Link key={countryIndex} href={DisplayUtil.getCountryRoute(version === Types.VERSION.PEAKS ? Types.App.DataTypes.TrekOrClimb.CLIMB : Types.App.DataTypes.TrekOrClimb.TREK, trip.continent, isoCode)}>
										<CountryBadge isoCode={isoCode} />
									</Link>)}
							</MantineCore.Group>

							<MantineCore.Text fz="sm" c="dimmed" mt="sm" lineClamp={4}>
								<HTMLContent html={trip.blurb}></HTMLContent>
							</MantineCore.Text>

							<MantineCore.Group position="apart" mt="md">
								<div>
									{!!trip?.lowestPrice && <>
											<MantineCore.Text fz="xl" span fw={500} className={'price'}>
												from ${trip.lowestPrice?.toLocaleString()}
											</MantineCore.Text>
											{/* <MantineCore.Text span fz="sm" c="dimmed">
            {' '}
            / night
            </MantineCore.Text> */}
										</>}
								</div>

								<Link href={DisplayUtil.getTrekRoute(version === Types.VERSION.PEAKS ? Types.App.DataTypes.TrekOrClimb.CLIMB : Types.App.DataTypes.TrekOrClimb.TREK, trip.continent, trip.countries, trip.slug)}>
									<MantineCore.Button radius="md">View</MantineCore.Button>
								</Link>
							</MantineCore.Group>
						</MantineCore.Card>
					</Carousel.Slide>)}
			</Carousel>
		</div>;
};
export default TripsCarousel;